import Alpine from 'alpinejs'
import data from './assets/1698373992675.json';

import('@dotlottie/player-component');

window.Alpine = Alpine
Alpine.data('dotlottie', () => ({
    data: JSON.stringify(data),
}))

Alpine.start()

function hashHandler() {
    activeTab(location.hash);
    history.pushState(null, null, window.location.href.split("#")[0]);
}

function activeTab(href) {

    document.querySelectorAll('a.active').forEach((e) => {
        e.classList.remove('active');
    });

    if(href) {
        const link = document.querySelector(`[href="${href}"]`);

        if (link) {
            link.classList.add('active')
        }
    }
}

window.addEventListener('scroll', (e) => {

    let focus;

    document.querySelectorAll('section').forEach((e) => {
        if (window.scrollY > (e.offsetTop - window.innerHeight / 2))
            focus = e;
    });

    if (focus) {
        activeTab(`#${focus.id}`);
    } else {
        activeTab();
    }

}, false);

window.addEventListener('hashchange', hashHandler, false);